<template>
  <div class="page-background">
    <transition name="fade">
      <div
        class="background-layer"
        :key="currentStep"
        :style="backgrounds[currentStep]"
      ></div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "MenopauseAssessmentPageBackground",
  props: {
    currentStep: {
      type: Number,
      default: 1
    },
    backgrounds: {
      type: Array
    }
  },
  data() {
    return {
      previousBackground: {},
      currentBackground: {}
    };
  },
  watch: {
    currentStep: {
      handler(newStep, oldStep) {
        this.previousBackground = this.backgrounds[oldStep] || {};
        this.currentBackground = this.backgrounds[newStep] || {};
      },
      immediate: true
    }
  }
};
</script>
<style scoped lang="scss">
.page-background {
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: var(--topbar-height);
  z-index: 0;

  .background-layer {
    position: absolute;
    inset: 0;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter {
  opacity: 0;
}

.fade-leave-active {
  // Keeps previous background visible under the new one
  opacity: 1;
}

.fade-enter-to {
  opacity: 1;
}

.fade-leave-to {
  // Fade out the previous background when the new one is visible
  opacity: 0;
}
</style>
