import i18n from "@/plugins/i18n.js";
import BodyAreas from "@/components/painareas/body-areas.json";

export class OutroService {
  getStageContent({
    stage,
    contraceptiveInjectables,
    hormoneReplacementTherapy
  }) {
    const stageAnswerKey = {
      1: "notStarted",
      2: "perimenopause",
      3: "postmenopause",
      98: "notSure"
    }[stage];

    const isMenopauseStage = stage === 2 || stage === 3;

    let titleKey;

    if (stage === 1) {
      titleKey = "notSure";
    } else if (isMenopauseStage) {
      titleKey = "menopause";
    } else if (stage === 98) {
      titleKey = "notSure";
    }

    return {
      title: `menopauseAssessment.outroPage.stage.heading.${titleKey}`,
      titleParams: {
        stage: i18n
          .t(`menopauseAssessment.stages.${stageAnswerKey}`)
          .toLowerCase()
      },
      paragraphs: [
        ...this.getTranslationArray(
          `menopauseAssessment.outroPage.stage.${stageAnswerKey}`
        ),
        hormoneReplacementTherapy === 1
          ? "menopauseAssessment.outroPage.stage.hrt"
          : null,
        contraceptiveInjectables === 1
          ? "menopauseAssessment.outroPage.stage.ihc"
          : null,
        isMenopauseStage ? "menopauseAssessment.outroPage.stage.vidaHelp" : null
      ].filter(Boolean)
    };
  }

  getPainContent(results) {
    const hasPain = Object.values(results.pain?.areas).some(
      area => area.level > 0
    );
    if (!hasPain) {
      return {
        title: "menopauseAssessment.outroPage.pain.heading.noPain",
        paragraphs: ["menopauseAssessment.outroPage.pain.noPainText"]
      };
    }
    const translatedAreas = Object.keys(results.pain?.areas).map(area => {
      return i18n.t(BodyAreas[area].shortenedName).toLowerCase();
    });

    const connector = i18n.t("form.generator.connector");

    const formatAreasList = areas => {
      if (areas.length === 0) return "";
      if (areas.length === 1) return areas[0];
      if (areas.length === 2) return `${areas[0]} ${connector} ${areas[1]}`;
      return `${areas.slice(0, -1).join(", ")}, ${connector} ${
        areas[areas.length - 1]
      }`;
    };

    const formattedAreas = formatAreasList(translatedAreas);

    return {
      title: `menopauseAssessment.outroPage.pain.heading.${
        translatedAreas.length > 1 ? "multiple" : "single"
      }`,
      titleParams: { area: translatedAreas },
      paragraphs: this.getTranslationArray(
        "menopauseAssessment.outroPage.pain.text"
      ).map(text => text.replace("{area}", formattedAreas))
    };
  }

  getSymptomsContent(symptoms) {
    const symptomMap = {
      1: "hotFlashes",
      2: "fatigue",
      3: "moodChanges",
      4: "concentration",
      5: "memory",
      6: "wakingUp",
      7: "reducedStrength",
      98: "other"
    };

    const filteredSymptoms = symptoms
      .filter(symptomId => symptomId !== 98)
      .slice(0, 3);

    return {
      title: "menopauseAssessment.outroPage.symptoms.heading",
      titleParams: {},
      paragraphs: [
        ...filteredSymptoms
          .filter(symptomId => symptomId !== 98)
          .map(symptomId =>
            i18n.t(
              `menopauseAssessment.outroPage.symptoms.symptoms.${symptomMap[symptomId]}`
            )
          ),
        i18n.t("menopauseAssessment.outroPage.symptoms.reportFooter")
      ]
    };
  }

  getTranslationArray(key) {
    return Array.isArray(i18n.t(key)) ? i18n.t(key) : [i18n.t(key)];
  }
}
