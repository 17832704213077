import PainRecommendations from "@/components/assessment/menopause/report/recommendations/PainRecommendations.json";
import { LinkTypes } from "@/services/recommendations/link-types.js";

const cardGroups = {
  painSoothers: {
    titleKey:
      "menopauseAssessment.report.recommendations.pain.groups.painSoothers.title",
    cards: {
      menopause_heat_therapy: {
        showConditions: results => !hasTriedPainRelief(results, 2)
      },
      menopause_self_massage: {
        showConditions: results => !hasTriedPainRelief(results, 4)
      },
      menopause_sleep_posture: {
        showConditions: () => true
      },
      menopause_micro_walks: {
        showConditions: () => true
      }
    }
  },
  neck: {
    titleKey:
      "menopauseAssessment.report.recommendations.pain.groups.neck.title",
    cards: {
      menopause_neck_tilt: {
        showConditions: results => hasPainAreas(results, ["neck"])
      },
      menopause_neck_rotation: {
        showConditions: results => hasPainAreas(results, ["neck"])
      },
      menopause_chin_tuck: {
        showConditions: results => hasPainAreas(results, ["neck"])
      }
    }
  },
  shoulder: {
    titleKey:
      "menopauseAssessment.report.recommendations.pain.groups.shoulder.title",
    cards: {
      menopause_shoulder_squeeze: {
        showConditions: results =>
          hasPainAreas(results, ["leftshoulder", "rightshoulder"])
      },
      menopause_shoulder_roll: {
        showConditions: results =>
          hasPainAreas(results, ["leftshoulder", "rightshoulder"])
      },
      menopause_across_chest_stretch: {
        showConditions: results =>
          hasPainAreas(results, ["leftshoulder", "rightshoulder"])
      }
    }
  },
  midBack: {
    titleKey:
      "menopauseAssessment.report.recommendations.pain.groups.midBack.title",
    cards: {
      menopause_spinal_twist: {
        showConditions: results => hasPainAreas(results, ["midback"])
      },
      menopause_seated_cat_cow: {
        showConditions: results => hasPainAreas(results, ["midback"])
      },
      menopause_shoulder_squeeze: {
        showConditions: results => hasPainAreas(results, ["midback"])
      }
    }
  },
  lowerBack: {
    titleKey:
      "menopauseAssessment.report.recommendations.pain.groups.lowerBack.title",
    cards: {
      menopause_seated_forward_bend: {
        showConditions: results => hasPainAreas(results, ["lowerback"])
      },
      menopause_knee_to_chest: {
        showConditions: results => hasPainAreas(results, ["lowerback"])
      },
      menopause_seated_side_bend: {
        showConditions: results => hasPainAreas(results, ["lowerback"])
      }
    }
  },
  elbow: {
    titleKey:
      "menopauseAssessment.report.recommendations.pain.groups.elbow.title",
    cards: {
      menopause_tricep_stretch: {
        showConditions: results =>
          hasPainAreas(results, ["leftelbow", "rightelbow"])
      },
      menopause_wrist_flexor_stretch: {
        showConditions: results =>
          hasPainAreas(results, ["leftelbow", "rightelbow"])
      },
      menopause_desk_extension_stretch: {
        showConditions: results =>
          hasPainAreas(results, ["leftelbow", "rightelbow"])
      }
    }
  },
  wrist: {
    titleKey:
      "menopauseAssessment.report.recommendations.pain.groups.wrist.title",
    cards: {
      menopause_prayer_stretch: {
        showConditions: results =>
          hasPainAreas(results, ["leftwrist", "rightwrist"])
      },
      menopause_finger_spread_stretch: {
        showConditions: results =>
          hasPainAreas(results, ["leftwrist", "rightwrist"])
      },
      menopause_wrist_circles: {
        showConditions: results =>
          hasPainAreas(results, ["leftwrist", "rightwrist"])
      }
    }
  },
  gluteals: {
    titleKey:
      "menopauseAssessment.report.recommendations.pain.groups.gluteals.title",
    cards: {
      menopause_seated_figure_four: {
        showConditions: results => hasPainAreas(results, ["gluteals"])
      },
      menopause_cross_leg_twist: {
        showConditions: results => hasPainAreas(results, ["gluteals"])
      },
      menopause_forward_bend_glute_stretch: {
        showConditions: results => hasPainAreas(results, ["gluteals"])
      }
    }
  },
  hip: {
    titleKey:
      "menopauseAssessment.report.recommendations.pain.groups.hip.title",
    cards: {
      menopause_seated_figure_four: {
        showConditions: results =>
          hasPainAreas(results, ["righthip", "lefthip"])
      },
      menopause_seated_hip_flexor: {
        showConditions: results =>
          hasPainAreas(results, ["righthip", "lefthip"])
      },
      menopause_forward_bend_glute_stretch: {
        showConditions: results =>
          hasPainAreas(results, ["righthip", "lefthip"])
      }
    }
  },
  hamstring: {
    titleKey:
      "menopauseAssessment.report.recommendations.pain.groups.hamstring.title",
    cards: {
      menopause_seated_hamstring_stretch: {
        showConditions: results =>
          hasPainAreas(results, ["righthamstring", "lefthamstring"])
      },
      menopause_heel_slide_stretch: {
        showConditions: results =>
          hasPainAreas(results, ["righthamstring", "lefthamstring"])
      }
    }
  },
  calf: {
    titleKey:
      "menopauseAssessment.report.recommendations.pain.groups.calf.title",
    cards: {
      menopause_desk_toe_touch: {
        showConditions: results =>
          hasPainAreas(results, ["rightcalf", "leftcalf"])
      },
      menopause_ankle_circles: {
        showConditions: results =>
          hasPainAreas(results, ["rightcalf", "leftcalf"])
      }
    }
  },
  knee: {
    titleKey:
      "menopauseAssessment.report.recommendations.pain.groups.knee.title",
    cards: {
      menopause_seated_hamstring_stretch: {
        showConditions: results =>
          hasPainAreas(results, ["rightknee", "leftknee"])
      },
      menopause_knee_to_chest: {
        showConditions: results =>
          hasPainAreas(results, ["rightknee", "leftknee"])
      },
      menopause_seated_knee_extension: {
        showConditions: results =>
          hasPainAreas(results, ["rightknee", "leftknee"])
      }
    }
  },
  foot: {
    titleKey:
      "menopauseAssessment.report.recommendations.pain.groups.foot.title",
    cards: {
      menopause_seated_heel_raise: {
        showConditions: results =>
          hasPainAreas(results, ["rightankle", "leftankle"])
      },
      menopause_ankle_circles: {
        showConditions: results =>
          hasPainAreas(results, ["rightankle", "leftankle"])
      }
    }
  }
};

const mapCardToRecommendation = (cardId, cardData) => ({
  id: cardId,
  json: {
    imageName: cardData.imageName,
    heading: cardData.headingKey,
    subheading: cardData.subheadingKey,
    explanation: cardData.descriptionKey
  },
  linkType: LinkTypes.REGULAR
});

const hasPainAreas = (results, areas) => {
  if (!results?.pain?.areas) {
    return false;
  }

  const resultAreas = Object.keys(results.pain.areas);
  return resultAreas.some(x => areas.some(a => x.includes(a)));
};

const hasTriedPainRelief = (results, value) => {
  if (!results?.pain?.areas) {
    return false;
  }

  const areas = results.pain.areas;
  return Object.values(areas).some(area => {
    return (
      Array.isArray(area.menopausePainRelief) &&
      area.menopausePainRelief.includes(value)
    );
  });
};

const getVisibleCardsForGroup = (groupCards, results) => {
  return Object.entries(groupCards)
    .filter(([_, card]) => card.showConditions(results))
    .map(([cardId]) =>
      mapCardToRecommendation(cardId, PainRecommendations[cardId])
    );
};

const hasVisibleCards = (group, results) => {
  return Object.values(group.cards).some(card => card.showConditions(results));
};

export const getPainRecommendations = results => {
  const visibleGroups = Object.entries(cardGroups).reduce(
    (acc, [groupId, group]) => {
      if (hasVisibleCards(group, results)) {
        acc[groupId] = {
          titleKey: group.titleKey,
          cards: getVisibleCardsForGroup(group.cards, results)
        };
      }
      return acc;
    },
    {}
  );

  return {
    groups: visibleGroups
  };
};
