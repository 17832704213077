<template>
  <v-app-bar
    :app="$vuetify.breakpoint.smAndUp"
    :clipped-left="$vuetify.breakpoint.smAndUp"
    color="white"
    :style="{
      flexGrow: $vuetify.breakpoint.smAndUp ? '1' : '0'
    }"
    ref="topbar"
    :height="64"
  >
    <v-app-bar-nav-icon
      v-show="showSidebarBurgerMenu"
      @click.stop="$emit('input', !value)"
    />

    <div class="mr-4">
      <team-logo-and-name v-if="showTeamDetails" is-dark="true" />
      <vida-logo v-else :width="120" />
    </div>

    <LanguageSelection v-if="showLanguageDropDown" minimal class="ml-auto" />

    <div class="ml-auto" v-if="signedIn">
      <page-header-assessment v-if="isAssessmentRoute" />
      <page-header-game v-else-if="isGameRoute" />
      <page-header-actions v-else-if="showPageActions" />
      <vida-logo v-if="showVidaLogo" :is-icon="$vuetify.breakpoint.mdAndDown" />
    </div>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import LanguageSelection from "@/components/common/LanguageSelection.vue";
import FeatureFlagSelector from "@/components/internal/FeatureFlagSelector.vue";
import NotificationsBadge from "@/components/common/NotificationBadge.vue";
import debounce from "lodash/debounce";
import PageHeaderActions from "@/components/layout/PageHeaderActions.vue";
import TeamLogoAndName from "@/components/layout/TeamLogoAndName.vue";
import PageHeaderAssessment from "@/components/layout/PageHeaderAssessment.vue";
import VidaLogo from "@/components/layout/VidaLogo.vue";
import { routes } from "@/router/route-constants.js";
import PageHeaderGame from "@/components/layout/PageHeaderGame.vue";

const TOPBAR_CSS_VAR = "--topbar-height";

export default {
  name: "top-bar",
  components: {
    PageHeaderGame,
    VidaLogo,
    PageHeaderAssessment,
    LanguageSelection,
    TeamLogoAndName,
    PageHeaderActions,
    FeatureFlagSelector,
    NotificationsBadge
  },
  props: {
    value: Boolean,
    showSideBar: Boolean
  },
  computed: {
    ...mapGetters(["signedIn", "isAdminUser"]),
    showSidebarBurgerMenu() {
      return this.showSideBar && this.$vuetify.breakpoint.smAndDown;
    },
    showPageActions() {
      return !this.$route.meta?.hideActionMenuInTopBar;
    },
    showLanguageDropDown() {
      return !this.signedIn && !this.isAssessmentRoute;
    },
    isAssessmentRoute() {
      return this.$route.meta?.assessmentRoute;
    },
    isGameRoute() {
      return this.$route.meta?.gameRoute;
    },
    showVidaLogo() {
      return this.$route.name === routes.EMAIL_PAIN_CHECK_IN;
    },
    showTeamDetails() {
      return (
        this.signedIn &&
        this.$route.name &&
        this.$route.name !== routes.CREATE_TEAM &&
        this.$route.name !== routes.JOIN_TEAM &&
        this.$route.name !== routes.CHOOSE_TEAM
      );
    }
  },
  mounted() {
    this.setCssTopbarHeightVariable();
    this.addEventListeners();
  },
  beforeDestroy() {
    this.removeEventListeners();
  },
  methods: {
    setCssTopbarHeightVariable() {
      if (this.$refs.topbar?.$el) {
        const { offsetHeight } = this.$refs.topbar.$el;

        document.documentElement.style.setProperty(
          TOPBAR_CSS_VAR,
          `${offsetHeight}px`
        );
      }
    },
    debouncedResizeListener: debounce(function () {
      this.setCssTopbarHeightVariable();
    }, 200),
    addEventListeners() {
      window.addEventListener("resize", this.debouncedResizeListener);
    },
    removeEventListeners() {
      window.removeEventListener("resize", this.debouncedResizeListener);
    }
  }
};
</script>

<style scoped lang="scss">
.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0 0 0 1px hsl(0, 0%, 90%);
}

.bar-style {
  width: 1px;
  background-color: hsl(0, 0%, 90%);
}
[class^="logo"] {
  display: block;
  height: auto;
}
</style>
