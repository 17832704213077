<template>
  <div
    class="menopause-assessment-loader-container d-flex flex-column justify-center"
  >
    <lottie-animation
      :options="lottieOptions"
      :height="400"
      :width="400"
      class="mt-n12"
    />
    <h1 class="text-center text-h4 mb-0 mt-8">
      {{ $t("deskAssessment.loadingMessage") }}
    </h1>
  </div>
</template>

<script>
import LottieAnimation from "@/components/common/animations/LottieAnimation.vue";
import animationData from "@/assets/json/lottie/woman-waiting.json";

const LOADER_MIN_TIME = 4000; // ms

export default {
  name: "MenopauseAssessmentLoader",
  components: { LottieAnimation },
  props: {
    trigger: Boolean
  },
  data() {
    return {
      timerInterval: null,
      loaderTimeLeft: LOADER_MIN_TIME,
      lottieOptions: {
        animationData: animationData,
        loop: true,
        autoplay: true
      }
    };
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  },
  mounted() {
    this.startLoader();
  },
  methods: {
    startLoader() {
      this.timerInterval = setInterval(() => {
        this.loaderTimeLeft -= 10;
        if (this.loaderTimeLeft <= 0) {
          this.stopLoader();
        }
      }, 10);
    },
    async stopLoader() {
      clearInterval(this.timerInterval);
      this.loaderTimeLeft = LOADER_MIN_TIME;
      this.$emit("close");
    }
  }
};
</script>

<style scoped lang="scss">
.menopause-assessment-loader-container {
  min-height: var(--app-content-height);
}
</style>
