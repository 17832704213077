<template>
  <svg
    width="652"
    height="450"
    viewBox="0 0 652 450"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        :id="gradientId"
        :x1="config.x"
        y1="218.5"
        :x2="config.x + config.width"
        y2="218.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#005F61" />
        <stop offset="1" stop-color="#005F61" stop-opacity="0" />
      </linearGradient>
    </defs>
    <rect
      :x="config.x"
      y="61"
      :width="config.width"
      height="315"
      :fill="`url(#${gradientId})`"
      fill-opacity="0.2"
    />

    <text
      fill="#2A3C3C"
      :x="config.textX"
      y="75.362"
      style="font-family: Manrope; font-size: 14px; font-weight: 500"
    >
      {{ config.text }}
    </text>
    <path
      d="M109 375C131.833 365.516 178.4 345.749 182 342.555C186.5 338.562 230.5 297.132 232 283.655C233.5 270.177 232 211.277 232 202.791C232 194.306 227.5 143.392 285.5 136.404C343.5 129.416 376 150.38 387 158.367C398 166.353 431 183.824 442.5 253.705C454 323.587 452 331.074 467 339.56C482 348.046 540 359.027 605 357.031"
      stroke="#005F61"
      stroke-width="5"
      stroke-linecap="round"
    />
    <path
      d="M109.5 377L109 377L109 377.5H109.5V377ZM109.854 60.6464C109.658 60.4512 109.342 60.4512 109.146 60.6464L105.964 63.8284C105.769 64.0237 105.769 64.3402 105.964 64.5355C106.16 64.7308 106.476 64.7308 106.672 64.5355L109.5 61.7071L112.328 64.5355C112.524 64.7308 112.84 64.7308 113.036 64.5355C113.231 64.3402 113.231 64.0237 113.036 63.8284L109.854 60.6464ZM602.354 377.354C602.549 377.158 602.549 376.842 602.354 376.646L599.172 373.464C598.976 373.269 598.66 373.269 598.464 373.464C598.269 373.66 598.269 373.976 598.464 374.172L601.293 377L598.464 379.828C598.269 380.024 598.269 380.34 598.464 380.536C598.66 380.731 598.976 380.731 599.172 380.536L602.354 377.354ZM110 377L110 61L109 61L109 377L110 377ZM109.5 377.5L602 377.5V376.5L109.5 376.5V377.5Z"
      fill="#5F7B7C"
    />
    <text
      fill="#2A3C3C"
      xml:space="preserve"
      style="white-space: pre"
      font-family="Manrope"
      font-size="14"
      font-weight="500"
      letter-spacing="0px"
    >
      <tspan x="109" y="401.362">0</tspan>
    </text>
    <text
      fill="#2A3C3C"
      xml:space="preserve"
      style="white-space: pre"
      font-family="Manrope"
      font-size="14"
      font-weight="500"
      letter-spacing="0px"
    >
      <tspan x="172" y="401.362">10</tspan>
    </text>
    <text
      fill="#2A3C3C"
      xml:space="preserve"
      style="white-space: pre"
      font-family="Manrope"
      font-size="14"
      font-weight="500"
      letter-spacing="0px"
    >
      <tspan x="239" y="401.362">20</tspan>
    </text>
    <text
      fill="#2A3C3C"
      xml:space="preserve"
      style="white-space: pre"
      font-family="Manrope"
      font-size="14"
      font-weight="500"
      letter-spacing="0px"
    >
      <tspan x="308" y="401.362">30</tspan>
    </text>
    <text
      fill="#2A3C3C"
      xml:space="preserve"
      style="white-space: pre"
      font-family="Manrope"
      font-size="14"
      font-weight="500"
      letter-spacing="0px"
    >
      <tspan x="310" y="426.362">Age (years)</tspan>
    </text>
    <text
      transform="matrix(0 -1 1 0 49 361)"
      fill="#2A3C3C"
      xml:space="preserve"
      style="white-space: pre"
      font-family="Manrope"
      font-size="14"
      font-weight="500"
      letter-spacing="0px"
    >
      <tspan x="0" y="14.362">Level of oestrogen in the blood</tspan>
    </text>
    <text
      fill="#2A3C3C"
      xml:space="preserve"
      style="white-space: pre"
      font-family="Manrope"
      font-size="14"
      font-weight="500"
      letter-spacing="0px"
    >
      <tspan x="377" y="401.362">40</tspan>
    </text>
    <text
      fill="#2A3C3C"
      xml:space="preserve"
      style="white-space: pre"
      font-family="Manrope"
      font-size="14"
      font-weight="500"
      letter-spacing="0px"
    >
      <tspan x="448" y="401.362">50</tspan>
    </text>
    <text
      fill="#2A3C3C"
      xml:space="preserve"
      style="white-space: pre"
      font-family="Manrope"
      font-size="14"
      font-weight="500"
      letter-spacing="0px"
    >
      <tspan x="517" y="401.362">60</tspan>
    </text>
    <text
      fill="#2A3C3C"
      xml:space="preserve"
      style="white-space: pre"
      font-family="Manrope"
      font-size="14"
      font-weight="500"
      letter-spacing="0px"
    >
      <tspan x="587" y="401.362">70</tspan>
    </text>
  </svg>
</template>
<script>
const Config = {
  PERIMENOPAUSE: {
    x: 386,
    width: 62,
    text: "Perimenopause",
    textX: 391
  },
  MENOPAUSE: {
    x: 448,
    width: 41,
    text: "Menopause",
    textX: 461
  },
  POSTMENOPAUSE: {
    x: 489,
    width: 113,
    text: "Postmenopause",
    textX: 501
  }
};

export default {
  name: "MenopauseGraph",
  props: {
    stage: {
      type: String,
      default: "perimenopause"
    }
  },
  computed: {
    config() {
      return Config[this.stage?.toUpperCase()];
    },
    gradientId() {
      return `paint_${this.stage}`;
    }
  }
};
</script>
