import DatapointGeneration from "@/services/assessment-results-datapoint-generation";
import { storeAssessmentData } from "@/customApi";

export default async function SaveAssessment(
  assessmentId,
  results,
  assessmentType,
  schema,
  score
) {
  const answers = packageAnswers(schema, results);

  let result = {
    AssessmentId: assessmentId,
    AssessmentSection: schema.theme,
    MarkAssessmentAsCompleted: score === 0 || score > 0,
    Values: answers,
    AssessmentType: assessmentType,
    Score: score
  };

  await storeAssessmentData(result);
}

function packageAnswers(schema, results) {
  // If pain, loop through all pain areas and add all answers
  if (schema.theme === "pain") {
    const answers = [];
    Object.entries(results.areas).forEach(([area, result]) => {
      var questions = schema.questions[area];
      answers.push(addSectionAnswers(questions, result, area));
    });
    return answers.flat();
  }
  return addSectionAnswers(schema.questions, results, null);
}

// Match the question name to the schema question and generate a data point
function addSectionAnswers(questions, results, group) {
  const answers = [];
  Object.entries(results).forEach(([name, value]) => {
    const question = questions.find(aq => aq.name === name);
    DatapointGeneration.addAnswer(question, value, answers, name, group);
  });
  return answers;
}
