<template>
  <div class="menopause-report-header pa-8">
    <woman-on-mountains-illustration class="background-image" />
    <div class="content">
      <div class="content-inner">
        <h1
          class="text-overline vflNeutralDark1--text mb-1"
          style="font-size: 1rem !important"
        >
          {{ title }}
        </h1>
        <h3
          class="text-h2 text-body-darkest"
          style="line-height: 1.2 !important"
        >
          {{ content }}
        </h3>
      </div>
    </div>
  </div>
</template>
<script>
import WomanOnMountainsIllustration from "@/components/assessment/menopause/report/WomanOnMountainsIllustration.vue";
import { menopauseReportService } from "@/components/assessment/menopause/report/text-service.js";

export default {
  name: "MenopauseReportHeader",
  components: { WomanOnMountainsIllustration },
  props: {
    results: {
      type: Object,
      required: true
    }
  },
  computed: {
    content() {
      return menopauseReportService.getHeaderContent(this.results);
    },
    title() {
      return menopauseReportService.getHeaderTitle(this.results);
    }
  }
};
</script>

<style scoped lang="scss">
.menopause-report-header {
  align-items: center;
  background-color: var(--v-vflBeigeLight1-base);
  display: flex;
  min-height: 400px;
  overflow: hidden;
  position: relative;

  .background-image {
    bottom: 0;
    position: absolute;
    right: 0;

    @media screen and (max-width: 1100px) {
      opacity: 0.4;
    }

    @media screen and (max-width: 1000px) {
      display: none;
    }
  }

  .content {
    flex-grow: 1;
    margin: -2rem auto 0;
    max-width: 1320px;

    &-inner {
      max-width: 65ch;
      position: relative;
    }
  }
}
</style>
