<template>
  <menopause-report-section :section-number="3">
    <template #title>{{ sectionTitle }}</template>
    <template #content>
      <div class="vfl-card-light py-4 px-8 pb-12 d-flex align-center">
        <img
          src="/images/illustrations/muscle-strength-decrease.svg"
          alt=""
          aria-hidden="true"
        />
        <div class="content">
          <p v-for="p in content" :key="p">{{ p }}</p>
          <did-not-know-that-fact-button
            class="mt-8"
            fact="Preventing muscle loss"
          />
        </div>
      </div>
    </template>
  </menopause-report-section>
</template>

<script>
import MenopauseReportSection from "@/components/assessment/menopause/report/MenopauseReportSection.vue";
import { menopauseReportService } from "@/components/assessment/menopause/report/text-service.js";
import DidNotKnowThatFactButton from "@/components/assessment/menopause/report/DidNotKnowThatFactButton.vue";

export default {
  name: "MenopauseReportMuscleLoss",
  components: {
    DidNotKnowThatFactButton,
    MenopauseReportSection
  },
  props: {
    results: {
      type: Object,
      required: true
    }
  },
  computed: {
    sectionTitle() {
      return menopauseReportService.getMuscleLossSectionTitle();
    },
    content() {
      return menopauseReportService.getMuscleLossCardContent();
    }
  }
};
</script>
<style scoped lang="scss">
.vfl-card-light {
  gap: 1rem;
}
</style>
