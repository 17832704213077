export const STAGES = {
  PERIMENOPAUSE: "perimenopause",
  MENOPAUSE: "menopause",
  POSTMENOPAUSE: "postmenopause"
};

export const TREATMENT_TYPES = {
  INJECTABLE_CONTRACEPTIVES: "injectable_contraceptives",
  HRT: "hrt"
};

export const STAGE_MAPPING = {
  2: STAGES.PERIMENOPAUSE,
  3: STAGES.POSTMENOPAUSE
};
