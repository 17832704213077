<template>
  <menopause-report-section :section-number="1">
    <template #title>{{ title }}</template>
    <template #content>
      <p>{{ introText }}</p>
      <vfl-tabs :initial-tab="initialTab">
        <vfl-tab v-for="tab in tabs" :key="tab.type" :title="tab.title">
          <div class="tab-wrapper">
            <menopause-report-graph :type="tab.type" />
            <menopause-report-info-accordion :active-tab="tab.type" />
          </div>
        </vfl-tab>
      </vfl-tabs>
    </template>
  </menopause-report-section>
</template>

<script>
import MenopauseReportSection from "@/components/assessment/menopause/report/MenopauseReportSection.vue";
import VflTabs from "@/components/vitrue-foundation-library/tabs/VflTabs.vue";
import VflTab from "@/components/vitrue-foundation-library/tabs/VflTab.vue";
import MenopauseReportGraph from "@/components/assessment/menopause/report/MenopauseReportGraph.vue";
import MenopauseReportInfoAccordion from "@/components/assessment/menopause/report/MenopauseReportInfoAccordion.vue";
import { menopauseReportService } from "@/components/assessment/menopause/report/text-service.js";
import {
  STAGE_MAPPING,
  STAGES
} from "@/components/assessment/menopause/report/constants/index.js";
import {
  TABS,
  TAB_INDICES
} from "@/components/assessment/menopause/report/constants/msk-health-tabs.js";

export default {
  name: "MenopauseReportMskHealth",
  components: {
    MenopauseReportInfoAccordion,
    MenopauseReportGraph,
    VflTab,
    VflTabs,
    MenopauseReportSection
  },
  props: {
    results: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tabs: TABS
    };
  },
  computed: {
    initialTab() {
      const { stage } = this.results.stage;
      const mappedStage = STAGE_MAPPING[stage];

      if (mappedStage === STAGES.PERIMENOPAUSE) {
        return TAB_INDICES.PERIMENOPAUSE;
      }

      if (mappedStage === STAGES.POSTMENOPAUSE) {
        return TAB_INDICES.POSTMENOPAUSE;
      }

      return TAB_INDICES.MENOPAUSE;
    },
    title() {
      return menopauseReportService.getMskHealthTitle();
    },
    introText() {
      return menopauseReportService.getMskHealthIntroText();
    }
  }
};
</script>

<style scoped lang="scss">
.tab-wrapper {
  align-items: start;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;
}
</style>
