<template>
  <div :style="style" ref="lavContainer"></div>
</template>

<script>
import lottie from "lottie-web";

export default {
  name: "LottieAnimation",
  props: {
    options: {
      type: Object,
      required: true
    },
    height: {
      type: Number,
      default: 400
    },
    width: {
      type: Number,
      default: 400
    }
  },
  data() {
    return {
      style: {
        width: this.width + "px",
        height: this.height + "px",
        overflow: "hidden",
        margin: "0 auto"
      }
    };
  },
  mounted() {
    this.animation = lottie.loadAnimation({
      container: this.$refs.lavContainer,
      renderer: "svg",
      loop: this.options.loop !== false,
      autoplay: this.options.autoplay !== false,
      animationData: this.options.animationData,
      rendererSettings: this.options.rendererSettings || {}
    });

    this.$emit("animationCreated", this.anim);
  },
  beforeDestroy() {
    this.anim.destroy();
  }
};
</script>
